<template>
    <div>
        <v-bottom-sheet v-model="dialog">
            <v-list class="pa-0">
                <v-list-item :key="year" v-for="year in years" @click="save(year)">
                    <v-list-item-title class="text-center text-subtitle-1" v-text="year"></v-list-item-title>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>


<script>

export default {
    name: 'YearCalendarPicker',
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        years() {
            const year = new Date().getFullYear()
            const length = year - 2021
            var years = []
            for(var i = 0; i <= length; i++) {
                years.splice(0, 0, (year + i).toString())
            }
            return years
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        save: function(year) {
            this.$emit('selected', year)
            this.close()
        }
    }
}

</script>

<style scoped>

/deep/ .v-list-item {
    min-height: 56px !important;
}

</style>