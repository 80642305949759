<template>
    <div>
        <v-app-bar color="primary" class="white--text text-h5 text-center">
            <v-btn icon class="white--text" :ripple="false" @click="leftIconEventHandler" v-if="leftIcon">
                <v-icon v-text="leftIcon"></v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title v-text="title"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon class="white--text" :ripple="false" @click="rightIconEventHandler" v-if="rightIcon">
                <v-icon v-text="rightIcon"></v-icon>
            </v-btn>
        </v-app-bar>
    </div>
</template>


<script>

export default {
    name: 'AppTopBar',
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        rightIcon: {
            type: String,
            required: false,
            default: ''
        },
        leftIcon: {
            type: String,
            required: false,
            default: ''
        },
        leftUrl: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        leftIconEventHandler: function() {
            this.$emit('leftIconEvent')
        },
        rightIconEventHandler: function() {
            this.$emit('rightIconEvent')
        }
    }
}

</script>