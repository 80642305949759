<template>
    <div>
        <app-top-bar title="My Payslip" left-icon="mdi-arrow-left" right-icon="mdi-calendar" @leftIconEvent="back" @rightIconEvent="openCalendarDialog"></app-top-bar>
        <van-pull-refresh pulling-text="Pull to refresh" loosing-text="Release to refresh" loading-text="Loading..." @refresh="refresh" v-model="refreshing">
            <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
            </v-layout>
            <v-layout align-center justify-center :style="getStyle()" v-if="!loading && items.length === 0">You do not have any payslip yet</v-layout>
            <div :style="getStyle()" v-if="!loading && items.length > 0">
                <div :key="index" v-for="(item, index) in items">
                    <p class="px-4 pt-2 pb-1 mb-0 text-h6" v-text="item.date"></p>
                    <v-card class="elevation-0 rounded-0 mb-2" :ripple="false" :key="sindex" v-for="(shift, sindex) in item.shifts" @click="go(shift)">
                        <v-card-text class="py-0">
                            <v-list class="transparent">
                                <v-list-item>
                                    <v-list-item-title>Project Name</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.project"></v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Working Date</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.work_date"></v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Working Time</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.working_time"></v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Outlet Name</v-list-item-title>
                                    <v-list-item-subtitle class="text-right" v-text="shift.outlet"></v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-divider></v-divider>
                    </v-card>
                </div>
            </div>
        </van-pull-refresh>
        <year-picker ref="yearPicker" @selected="updateYear"></year-picker>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { redirectNameTo } from '@/utils/route'
import AppTopBar from '../../layout/AppTopBar.vue'
import YearCalendarPicker from '../../shared/YearCalendarPicker.vue'

export default {
    name: 'PayslipHistory',
    components: {
        appTopBar: AppTopBar,
        yearPicker: YearCalendarPicker
    },
    data() {
        return {
            loading: true,
            refreshing: false,
            filters: {
                year: ''
            }
        }
    },
    computed: {
        yearPicker() {
            return this.$refs.yearPicker
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
			this.$store.dispatch('mobile/payslip/getPayslips', this.filters).then((response) => {
                this.loading = false
                this.items = response.data.items
            }).catch(() => {
                this.loading = false
            })
		},
		refresh() {
            delay(() => {
                this.get()
                this.refreshing = false
            }, 1000)
        },
        getStyle: function() {
            return {"min-height": (window.innerHeight - 56).toString() + "px"}
        },
        back: function() {
            redirectNameTo(this.$router, 'mobile-my-account')
        },
        go: function(item) {
            redirectNameTo(this.$router, 'mobile-my-payslip', {code: item.code})
        },
        openCalendarDialog: function(){
            this.yearPicker.open()
        },
        updateYear: function(item){
            this.filters.year = item
            this.get()
        }
    }
}

</script>


<style scoped>

</style>